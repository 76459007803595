@import "reset";

// Mixins
$breakpoints: (
  sm: 500px,
  md: 725px,
  lg: 1001px
);

@mixin respond-to($size) {
  @media only screen and (min-width: map-get($breakpoints, $size)) {
    @content;
  }
}

@mixin respond-between($min, $max) {
  @media only screen and (min-width: map-get($breakpoints, $min)) and (max-width: map-get($breakpoints, $max)) {
    @content;
  }
}

@mixin respond-up-to($size) {
  @media only screen and (max-width: map-get($breakpoints, $size)) {
    @content;
  }
}

// Vars
$globalMaxWidth: 97rem;
$white: #ffffff;
$black: #000000;

// Typography

html {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 62.5%;
  line-height: 1.5;
}

h1 {
  font-size: 3.6rem;
  line-height: 3.9rem;

  &:first-letter {
    text-transform: capitalize;
  }
}

h2 {
  margin: 0;
  font-size: 2rem;
  line-height: 2.9rem;
  font-weight: normal;
}


p {
  font-size: 1.6rem;
}

body {
  background: #e3e3e3;
  margin: 0;
}

.site-container {
  display: flex;

  &__inner {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

#app-container {
  flex-grow: 1;
  position: relative;

  @include respond-up-to(sm) {
    background: $white;
  }
}

.header {
  color: $white;
  border-bottom: 2px solid #cacaca;

  @include respond-to(sm) {
    border-bottom: 0;
  }

  background: #2d2d2e;
  line-height: 1;

  &__main-area {
    padding: 2rem 1rem;
    flex-direction: column;

    @include respond-to(sm) {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }

    @include respond-to(lg) {
      padding-left: 0;
      padding-right: 0;
    }

    display: flex;
    max-width: $globalMaxWidth;
    margin: 0 auto;

    &__app-title {
      font-size: 3.6rem;
      line-height: 3.9rem;
      font-weight: bold;
    }

    &__menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;

      @include respond-to(sm) {
        margin-top: 0;
      }

      &__item {
        a {
          color: $white;
        }

        @include respond-to(sm) {
          margin-right: 2rem;
        }
      }
    }
  }

  &__user-info-bar {
    padding: 2rem 1rem;
    padding-top: 0rem;
    padding-bottom: 2rem;
    justify-content: flex-end;
    align-items: center;
    max-width: $globalMaxWidth;
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    @include respond-to(lg) {
      padding-right: 0;
    }

    &__element {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    &__divider {
      height: 1.5rem;
      width: 0.1rem;
      background-color: white;
      margin-left: 1rem;
      margin-right: 2rem;

      @include respond-up-to(sm) {
        display: none;
      }
    }

    &__idporten-logo {
      width: 2.5rem;
      height: 2.5rem;
      background-size: contain;

      @include respond-up-to(mg) {
        margin-right: 0rem;
      }
    }

    &__person {
      background-color: #2d2d2e;
      margin-right: 1rem;

      @include respond-up-to(sm) {
        display: none;
      }
    }

    &__item {
      font-size: 1.5rem;

      a {
        color: $white;
      }

      margin-right: 2rem;

      @include respond-up-to(sm) {
        margin-right: 0rem;
        margin-left: 1rem;
      }
    }

    &__hide {
      @extend .header__user-info-bar__item;

      @include respond-up-to(sm) {
        display: none;
      }
    }
  }
}

.header-logo-bar-wrapper {
  background: $black;
}

.header-logo-bar__link {
  margin: 0;
  padding: 0;
  color: $white;

  &:hover {
    color: #96c1d1;
    text-decoration: none;
  }
}

.header-logo-bar {
  padding: 1rem 1rem;

  @include respond-to(lg) {
    padding: 1rem 0;
  }

  max-width: $globalMaxWidth;
  margin: 0 auto;
  line-height: 0;

  img {
    height: 1.3rem;
  }
}

.footer__inner-wrapper {
  max-width: 97rem;
  margin: 0 1rem;
  padding: 3rem 0;

  @include respond-to(lg) {
    margin: 0 auto;
  }

  display: block;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;

  @media only screen and (min-width: 725px) {
    display: flex;
  }
}

.footer {
  margin: 0;
  padding: 1rem;
  background: $black;
}

.footer-content {
  color: #ffffff;
  margin-left: 2rem;

  @include respond-to(lg) {
    display: flex;
  }
}

.footer-contact-info,
.footer-maintainer-info,
.footer-terms-info {
  margin-top: 1rem;
  display: block;
  padding-top: 1rem;
  margin-right: 0;


  @media only screen and (min-width: 725px) {
    display: inline-block;
    margin-right: 4rem;
    margin-top: 0;
    padding-top: 0;
  }
}

.info-text {
  font-size: 1.4rem;
  margin-top: 0.6rem;
}

.contact-link,
.uu-info-link,
.cookies-link {
  font-size: 1.4rem;
  margin-top: 1rem;
  color: $white;
  text-decoration: underline;
  border: none;
  box-shadow: none;
  background: $black;
  padding: 0.4rem 0;
  transition: all 0.3s ease;
}

.contact-link:hover {
  background: $white;
  color: $black;
}

.contact-link--box-shadow:hover {
  box-shadow: 0 0 0 2px $white;
}

// Spinner

.sk-circle-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
